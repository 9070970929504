<template>
  <div class="container mt-3">
    <loader v-if="loading"/>
    <div v-else class="row pricing bottommargin container">
      <div v-for="(item) in packageList" :key="item.id" class="col-md-4 pr-2 pl-2">
        <package-component :item="item"/>
      </div>
      <div class="col-md-4 pr-2 pl-2" v-if="event">
        <div class="pricing-box">
          <div class="pricing-title">
            <h3>Single Event</h3>
          </div>
          <div class="pricing-price">
            <span class="price-unit">$</span>{{ event.price }} <span
              style="font-size:14px; padding-top:35px;">{{ channelSettings.currency }}</span>
          </div>
          <div class="pricing-features">
            <div class="price-desc"> Buy access to {{ event.name }}</div>
          </div>
          <div class="pricing-action">
            <router-link :to="link" class="btn btn-outline-danger btn-block btn-lg">{{ $t('Buy') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapCacheActions} from "vuex-cache";
import {mapState} from "vuex";
import PackageComponent from "../league/components/main/PackageComponent";
import Loader from "@/components/Loader";
import {PACKAGE_TYPES} from "@/const";

export default {
  name: "SelectPackageScreen",
  components: {Loader, PackageComponent},
  data() {
    return {
      eventId: this.$route.params.id,
      event: null,
      geo_block: false, //TODO: remove or add to layout
      packageList: [],
      eventCat: [],
      loading: false,
      requestProcessed: 0,
    }
  },
  methods: {
    ...mapCacheActions(['GET_PARTICIPANTS_LIST', 'GET_EVENT_PACKAGES', 'GET_EVENT']),
    makeRequest() {
      this.GET_PARTICIPANTS_LIST();
    },
    getPackages() {
      const packagesPromise = Promise.allSettled([
        this.GET_EVENT_PACKAGES({limit: 100}).then((response) => {
          this.packageList = this.packageList.concat(response.data);
        }),
      ])
      packagesPromise.finally(() => {
        const singlePackage = this.packageList.find(({name, type})=>{
          return type === PACKAGE_TYPES.SINGLE;
        })
        if (singlePackage){
          this.$router.replace({
            name: 'buy',
            query: {
              event_id: this.eventId,
              single_event_package_id: singlePackage.id
            }
          })
        }
        this.loading = false;
      })
    }
  },
  computed: {
    ...mapState({
      participants: state => state.participant.participantsList,
      channelSettings: state => state.channel.settings,
    }),
    link() {
      return '/buy/?event_id=' + [this.eventId]
    }
  },
  created() {
    if (!this.$store.getters.isLogged) {
       return this.$router.push({name: 'login'})
    }
    this.loading = true;
    this.GET_EVENT(this.eventId)
        .then((data) => {
          const event = data.data;
          this.event = event;
          const noAccess = !data.data.has_access && !data.data.is_geo_blocked;
          this.loading = noAccess;
          if (noAccess) {
            this.eventCat[0] = data.data.event_category_id;
            if (this.eventCat.length === 0) {
              this.$router.replace({path: '/buy/?event_id=' + this.eventId})
            } else {
              this.getPackages();
            }
          } else if (event.is_geo_blocked) {
            this.geo_block = true;
          } else if (event.has_access || event.type !== "paid") {
            console.log('this.$route',this.$route)
            this.$router.replace({name: 'watch', params: {id: this.eventId}});
          }
        })
        .catch((data) => {
          if (Number(data.status) === 404) {
            this.$router.push({name: 'ErrorPage'})
          }
    });

    this.makeRequest();
  },
}
</script>

<style lang="scss" scoped>
.package-block {
  width: 100%;
}

.price-desc {
  padding: .4rem;
}

.pricing-box {
  margin-bottom: 1rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: 3px;
  text-align: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
}

.pricing-title {
  height: 5rem;
  padding: 15px 0;
  background-color: #F9F9F9;
  border-radius: 3px 3px 0 0;
  text-shadow: 1px 1px 1px #FFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;

  h3 {
    margin: 0;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #555;
  }
}

.pricing-price {
  position: relative;
  padding: 25px 0;
  color: #333;
  font-weight: 300;
  font-size: 40px;
  line-height: 1;
}

.pricing-price:after {
  content: '';
  position: absolute;
  width: 100px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  top: auto;
  bottom: 0;
  left: 50%;
  margin-left: -50px;
}

.pricing-features {
  min-height: 10rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);

  ul {
    margin: 0;
    padding: 15px 0;
    list-style: none;
  }

  li {
    padding: 6px 0;
    font-size: 14px;
  }
}

.pricing-action {
  padding: 15px 30px;

  .btn {
    margin-top: 3.8rem;
  }
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.19rem + 2px);
  width: 100%;
}

.team-select {
  margin-top: -.7rem;
  margin-bottom: -5.4rem;
}
</style>
